<script setup lang="ts">
import { onClickOutside } from '@vueuse/core';
import {
	ref,
	computed,
	onMounted,
	onBeforeUnmount,
} from 'vue';

import ChatbotBase from '@zyro-inc/site-modules/components/chatbot/ChatbotBase.vue';
import type {
	ChatbotTexts,
	ChatbotConversationMessage,
} from '@zyro-inc/site-modules/types';
import { MEDIA_MOBILE_SCREEN_BREAKPOINT } from '@zyro-inc/site-modules/constants';

type Props = {
  conversationHistory: ChatbotConversationMessage[];
  isResponding: boolean;
  isRestarting: boolean;
  texts: ChatbotTexts;
  areActionsDisabled: boolean;
  topPosition?: number;
  mobileBreakpoint?: number;
  isMobileEnabled?: boolean;
  isRestartVisible?: boolean;
  isClosable?: boolean;
  isIconButton?: boolean;
};

const emit = defineEmits<{
  initialize: [];
  respond: [message: string];
  restart: [];
  'function-click': [
    { name: string; arguments: Record<string, string> | undefined },
  ];
}>();

const props = withDefaults(defineProps<Props>(), {
	topPosition: 100,
	mobileBreakpoint: MEDIA_MOBILE_SCREEN_BREAKPOINT,
	isMobileEnabled: false,
	isRestartVisible: true,
	isClosable: true,
	isIconButton: true,
});

const isVisible = ref(false);
const isMobile = ref(false);
const isChatOpen = ref(false);
const topPosition = computed(() => `${props.topPosition}px`);

const toggleChat = async () => {
	if (!isChatOpen.value) {
		emit('initialize');
	}

	isChatOpen.value = !isChatOpen.value;
};

const chatbotBase = ref(null);

const handleScreenResize = () => {
	isMobile.value = window.innerWidth < props.mobileBreakpoint;
	isVisible.value = !isMobile.value || props.isMobileEnabled;
};

onMounted(() => {
	if (isChatOpen.value) {
		emit('initialize');
	}

	window.addEventListener('resize', handleScreenResize);

	handleScreenResize();

	// Handle for screen size not yet available when opening in new tab
	setTimeout(() => handleScreenResize(), 500);
});
onBeforeUnmount(() => {
	window.removeEventListener('resize', handleScreenResize);
});
onClickOutside(chatbotBase, () => {
	if (isChatOpen.value && isMobile.value) {
		isChatOpen.value = false;
	}
});
</script>

<template>
	<div
		v-show="isVisible"
		class="chatbot-wrapper"
		:class="{
			'chatbot-wrapper--hidden': !isChatOpen,
			'chatbot-wrapper--mobile': isMobile,
		}"
	>
		<Transition
			name="slide-top-bottom"
			appear
		>
			<ChatbotBase
				v-if="isChatOpen"
				ref="chatbotBase"
				:texts="texts"
				:is-responding="props.isResponding"
				:is-restarting="props.isRestarting"
				:conversation-history="props.conversationHistory"
				:is-restart-visible="props.isRestartVisible"
				:is-closable="props.isClosable"
				:is-icon-button="props.isIconButton"
				:are-actions-disabled="props.areActionsDisabled"
				@toggle-chat="toggleChat"
				@respond="emit('respond', $event)"
				@restart="emit('restart')"
			>
				<template #custom-content>
					<slot name="custom-content" />
				</template>
			</ChatbotBase>
		</Transition>
		<div
			class="chatbot-wrapper__resource-triggers"
			:class="{ 'chatbot-wrapper__resource-triggers--hidden': isChatOpen && isMobile, }"
		>
			<svg
				v-if="isIconButton"
				data-qa="ai-chatbot-icon-button"
				class="chatbot-wrapper__ai-chatbot-icon-button"
				xmlns="http://www.w3.org/2000/svg"
				width="88"
				height="88"
				viewBox="0 0 88 88"
				fill="none"
				@click="toggleChat"
			>
				<g filter="url(#filter0_d_1277_1001)">
					<rect
						x="12"
						y="8"
						width="64"
						height="64"
						rx="32"
						fill="currentColor"
					/>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M25 38.9681C25 31.806 30.806 26 37.9681 26H50.0319C57.194 26 63 31.806
            63 38.9681C63 46.1302 57.194 51.9362 50.0319 51.9362H48L40.516 57.1621C39.7016
            57.7308 39.2944 58.0152 38.9555 58.0001C38.6604 57.9869 38.3862 57.844 38.2064
            57.6095C38 57.3403 38 56.8436 38 55.8503V51.9362H37.9681C30.806 51.9362 25 46.1302 25
            38.9681ZM50.3559 34.5098L49.72 35.8667C49.5751 36.1691 49.1244 36.1691 48.9795
            35.8667L48.3436 34.502L46.935 33.8895C46.6211 33.7499 46.6211 33.3235 46.935 33.1839L48.3517
            32.5714L48.9875 31.2145C49.1324 30.9121 49.5751 30.9121 49.72 31.2145L50.3559 32.5792L51.7646
            33.1917C52.0785 33.3313 52.0785 33.7577 51.7646 33.8973L50.3559 34.5098ZM42.033 34.316L43.3128
            37.0297L46.13 38.2625C46.7579 38.5339 46.7579 39.3945 46.13 39.6737L43.3128 40.9065L42.033
            43.6203C41.7512 44.225 40.8578 44.225 40.568 43.6203L39.2881 40.9065L36.4709 39.6737C35.843
            39.4023 35.843 38.5417 36.4709 38.2625L39.2881 37.0297L40.568 34.316C40.8497 33.7112 41.7512
            33.7112 42.033 34.316ZM48.3436 43.4264L48.9795 42.0695C49.1244 41.7671 49.5751 41.7671 49.72
            42.0695L50.3559 43.4342L51.7646 44.0467C52.0785 44.1863 52.0785 44.6127 51.7646 44.7523L50.3479
            45.3648L49.712 46.7217C49.5671 47.0241 49.1244 47.0241 48.9795 46.7217L48.3436 45.3571L46.935
            44.7445C46.6211 44.605 46.6211 44.1785 46.935 44.0389L48.3436 43.4264Z"
						fill="white"
					/>
				</g>
				<defs>
					<filter
						id="filter0_d_1277_1001"
						x="0"
						y="0"
						width="88"
						height="88"
						filterUnits="userSpaceOnUse"
						color-interpolation-filters="sRGB"
					>
						<feFlood
							flood-opacity="0"
							result="BackgroundImageFix"
						/>
						<feColorMatrix
							in="SourceAlpha"
							type="matrix"
							values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
							result="hardAlpha"
						/>
						<feOffset dy="4" />
						<feGaussianBlur stdDeviation="6" />
						<feComposite
							in2="hardAlpha"
							operator="out"
						/>
						<feColorMatrix
							type="matrix"
							values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
						/>
						<feBlend
							mode="normal"
							in2="BackgroundImageFix"
							result="effect1_dropShadow_1277_1001"
						/>
						<feBlend
							mode="normal"
							in="SourceGraphic"
							in2="effect1_dropShadow_1277_1001"
							result="shape"
						/>
					</filter>
				</defs>
			</svg>
			<button
				v-else
				data-qa="ai-chatbot-button"
				class="chatbot-wrapper__ai-chatbot-button"
				@click="toggleChat"
			>
				<span>{{ texts.main.button }}</span>
			</button>
		</div>
		<div
			v-show="isChatOpen && isMobile"
			class="chatbot-wrapper__backdrop"
		/>
	</div>
</template>

<style scoped lang="scss">
.chatbot-wrapper {
  font-family: Arial, sans-serif;
  position: fixed;
  display: flex;
  flex-direction: column;
  height: calc(100% - v-bind(topPosition));
  width: 360px;
  right: 8px;
  bottom: 0;
  z-index: 999;
  justify-content: flex-end;

  &--hidden {
    pointer-events: none;
  }

  &--mobile {
    width: 100%;
    right: 0;
    overflow: visible;
  }

  &__resource-triggers {
    display: flex;
    margin-left: auto;
    z-index: 999;
    width: fit-content;
    padding: 4px;
    border-radius: 50%;
    margin-bottom: 8px;
    pointer-events: auto;

    &--hidden {
      display: none;
    }
  }

  &__ai-chatbot-icon-button {
    cursor: pointer;
    color: var(--color-indigo);

    &:hover {
      opacity: 0.9;
    }
  }

  &__ai-chatbot-button {
    font-family: Arial, sans-serif;
    background: var(--color-indigo);
    color: var(--color-light);
    cursor: pointer;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    box-shadow: 0px 4px 12px 0px #00000026;
    background: all 0.2s ease-in-out;

    &:hover {
      opacity: 0.9;
    }
  }

  &__backdrop {
    position: absolute;
    left: 0;
    background-color: rgba(#1d1e20, 0.35);
    width: 100vw;
    height: 100vh;
    z-index: 998;
    top: calc(-1 * v-bind(topPosition));
  }
}

.slide-top-bottom {
  &-enter-active,
  &-leave-active {
    transition: transform 0.15s cubic-bezier(0.2, 0.8, 0.2, 1.1),
      opacity 0.2s cubic-bezier(0.2, 0.8, 0.2, 1.1);
    transform-origin: bottom right;
  }

  &-enter-from,
  &-leave-to {
    opacity: 0;
    transform: scale(0.8);
  }

  &-enter-to,
  &-leave-from {
    opacity: 1;
    transform: scale(1);
  }
}
</style>
