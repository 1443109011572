<script lang="ts" setup>
import { computed } from 'vue';
import { EcommerceProductSorting } from '@zyro-inc/site-modules/types';
import { EcommerceSortingValue } from '@hostinger/builder-schema-validator';

interface Props {
	productSorting: EcommerceProductSorting
	sorting: EcommerceSortingValue;
	translations: Record<string, string>;
}

const props = defineProps<Props>();
const emit = defineEmits<{
	'sort-changed': [Event]
}>();
const computedSelectStyles = computed(() => ({
	color: props.productSorting.textColor || '#000',
}));
</script>

<template>
	<div class="product-list-sorting">
		<label
			for="sorting-options"
			:style="computedSelectStyles"
			class="product-list-sorting__label"
		>
			{{ translations.sortBy }}:
		</label>
		<div class="product-list-sorting__select-wrapper">
			<select
				id="sorting-options"
				class="product-list-sorting__select"
				:value="props.sorting"
				:style="computedSelectStyles"
				@change="emit('sort-changed', $event)"
			>
				<option
					v-for="option in productSorting.sortingOptions"
					:key="option.id"
					:value="option.value"
				>
					{{ translations[option.id] }}
				</option>
			</select>
		</div>
	</div>
</template>

<style lang="scss" scoped>
@import "@zyro-inc/site-modules/scss/mixins/site-engine-mobile";

.product-list-sorting {
	width: 100%;
	text-align: right;
	margin: 0 auto;

	&__label {
		margin-right: 12px;
		font-size: 14px;
	}

	&__select-wrapper {
		position: relative;
		display: inline-flex;
		align-items: center;

		&::after {
			content: "";
			position: absolute;
			right: 0;
			top: 50%;
			border: solid;
			border-color: inherit;
			border-width: 0 2px 2px 0;
			display: inline-block;
			padding: 3px;
			transform: translateY(-50%) rotate(45deg);
			pointer-events: none;
			margin: -2px 2px 0 0;
		}
	}

	&__select {
		background-color: transparent;
		outline: none;
		border: none;
		font-size: 14px;
		cursor: pointer;
		text-align: right;
		padding-right: 20px;
		appearance: none;
	}
}

@include site-engine-mobile {
	.product-list-sorting {
		&__select {
			max-width: 200px;
			text-wrap: wrap;
			width: 100%;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	}
}
</style>
